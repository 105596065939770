import React, { useEffect, useRef, useState } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { AppContext, useAppContext } from "../../../context/state";

const NavDrop = ({ menu }) => {
  const dropdownRef = useRef(null);
  const [isMouseEnter, setMouseEnter] = useState(false);
  const [isMouseLeave, setMouseLeave] = useState(false);
  const { store, setStore } = useAppContext(AppContext);

  const setStoreEventAndNavigate = (e) => {
    let leagueType = menu.league === "NBA" ? "nba" : menu.league === "WNBA" ? "wnba" : "college_mens";
    setStore({ ...store, leagueType: leagueType });
  };

  useEffect(() => {
    if (dropdownRef.current && (isMouseEnter || isMouseLeave)) {
      let dropId = document.getElementById(`${menu.league.replaceAll(" ", "-")}-nav-dropdown`);
      dropId.click();
    }
  }, [isMouseEnter, isMouseLeave]);

  return (
    <NavDropdown
      className="main"
      style={{ marginRight: "1rem" }}
      title={menu.league}
      id={`${menu.league.replaceAll(" ", "-")}-nav-dropdown`}
      onMouseEnter={() => setMouseEnter(true)}
      onMouseLeave={() => setMouseLeave(true)}
      ref={dropdownRef}
    >
      {menu.links.map((item, index) => (
        <NavDropdown.Item key={index} href={item.to} onClick={setStoreEventAndNavigate}>
          {item.name}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
};

export default NavDrop;
